import React from 'react';

import { StyledHeader, Container } from "./style";

export const Header = () => {
    const [isBlurred, setBlurred] = React.useState(false)

    React.useEffect(() => {
        typeof window !== "undefined" && window.addEventListener('scroll', () => {
            if(window.scrollY >= 250) {
                if(isBlurred == true) return;
                setBlurred(true)
            } else {
                if(isBlurred == false) return;
                setBlurred(false)
            }

            if(window.scrollY >= 860) {
                document.getElementById("down-indicator").style.opacity = "0";
            } else {
                document.getElementById("down-indicator").style.opacity = "1";
            }
        })
    })

    return (
        <StyledHeader isBlurred={isBlurred}>
            <Container>
                <div>
                    <img src={"https://github.com/EnderDev.png"} alt={"EnderDev Logo"} width={45} height={45} style={{ borderRadius: '64px', margin: 0 }} />
                </div>
                <div style={{ display: 'grid', gap: '20px', gridAutoFlow: 'column', marginLeft: '-45px', transition: '0.3s opacity', opacity: isBlurred ? 0 : 1, pointerEvents: isBlurred ? 'none' : 'all' }}>
                    <a target={"_blank"} href={"https://twitter.com/EnderDev_"}>Twitter</a>
                    <a target={"_blank"} href={"https://github.com/EnderDev"}>GitHub</a>
                    <a target={"_blank"} href={"https://invite.gg/dot"}>Discord</a>
                    <a target={"_blank"} href={"https://patreon.com/dothq"}>Donate</a>
                </div>
                <div></div>
            </Container>
        </StyledHeader>
    )
}
