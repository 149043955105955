import styled, { css } from "styled-components";

export const StyledHeader = styled.div`
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: 0.3s background-color, 0.3s box-shadow;

    ${({ isBlurred }: { isBlurred: boolean }) => css`
        backdrop-filter: blur(${isBlurred ? 8 : 0}px);
        background-color: rgb(255 255 255 / ${isBlurred ? 69 : 0}%);
        box-shadow: ${isBlurred ? '0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)' : ''};
    `};
`;

export const Container = styled.div`
    margin: 0px auto;
    max-width: 1164px;
    color: black;
    height: 80px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px;
    justify-content: space-between;

    a {
        font-size: 16px;
    }
`;