import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import { Icon } from '@dothq/icon'

const IndexPage = () => (
  <Layout>
    <SEO title="Kieran, also known as EnderDev" />
    <div className={"landing"}>
      <span className={"big-text"}>Hi, I'm <strong>Kieran</strong>, also known as <strong>EnderDev.</strong></span>
      <span className={"little-text"}>CEO and Founder of <a target={"_blank"} href={"https://dothq.co"}><strong>Dot HQ</strong></a></span>
      <span className={"cardboard-box"}>📦</span>
    </div>
    <Icon icon={"chevrons-down"} style={{ animation: '1s chevronbounce infinite alternate ease-in-out', transition: '0.4s opacity' }} size={28} id={"down-indicator"} />
    <div className={"landing project-item"}>
      <span className={"heading"}>Dot HQ</span>
      <span className={"project-name"}>Dot Browser</span>
      <span className={"project-description"}>Dot Browser was designed and developed primarily by me for Dot HQ. It has around 200 stars on GitHub and over 30,000 downloads.</span>
      <div>
        <a className={"project-link"} target={"_blank"} href={"https://dothq.co"} style={{ marginRight: '28px' }}>Visit site</a>
        <a className={"project-link"} target={"_blank"} href={"https://github.com/dothq/browser"}>Visit repository</a>
      </div>
      <img className={"project-display"} src={require("../images/project-images/dothq/dotbrowser-light.png")} />
    </div>
    <div className={"landing project-item gray"}>
      <span className={"heading"}>Dot HQ</span>
      <span className={"project-name"}>Website</span>
      <div style={{ marginTop: '18px' }}>
        <a className={"project-link"} target={"_blank"} href={"https://dothq.co"} style={{ marginRight: '28px' }}>Visit site</a>
        <a className={"project-link"} target={"_blank"} href={"https://github.com/dothq/dothq.co"}>Visit repository</a>
      </div>
      <img className={"project-display"} src={require("../images/project-images/dothq/dothq-website.png")} />
    </div>
    <div className={"landing project-item"}>
      <span className={"heading"}>Personal</span>
      <span className={"project-name"}>Notely</span>
      <span className={"project-description"}>Notely is a simple note-taking web application made by me as a submission for the Code Together coding challenge. I used Next, styled-components and Chakra UI.</span>
      <div>
        <a className={"project-link"} target={"_blank"} href={"https://notes.ender.site"} style={{ marginRight: '28px' }}>Visit site</a>
        <a className={"project-link"} target={"_blank"} href={"https://github.com/EnderDev/notely"}>Visit repository</a>
      </div>
      <img className={"project-display"} src={require("../images/project-images/enderdev/notely-website.png")} />
    </div>
  </Layout>
)

export default IndexPage
