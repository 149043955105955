import React from "react"
import PropTypes from "prop-types"

import { Header } from "./Header"
import "./layout.css"

const Layout = ({ children }) => {
  React.useEffect(() => {
    document.documentElement.style.opacity = 1;
  })

  return (
    <>
      <Header />
      <enderdev-main>{children}</enderdev-main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
